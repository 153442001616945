/* You can add global styles to this file, and also import other style files */

@font-face {
  font-family: 'MyTest';
  src: url('./assets/fonts/Helvetica Rounded Bold.otf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
.btn-wsp{
  position: fixed;
  width: 55px;
  height: 55px;
  line-height: 55px;
  bottom: 30px;
  right: 18px;
  background: #0df053;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 0px 1px 10px rgba(0,0,0,0.3);
  z-index: 400;
}
/*.btn-wsp:hover{*/
/*  background: #0df053;*/
/*  color: #fff;*/
/*  text-decoration: none;*/
/*}*/
.btn-wsp-pulse{
 animation: pulse-animation 2s infinite;
}
@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0px  rgba(0, 0, 0, 0.2);
  }
  100% {
    box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
  }
}


.title-heading {
  line-height: 26px;
}
.title-heading .heading {
  font-size: 45px !important;
  letter-spacing: 1px;
  color: #0037C6;
  font-weight: 900;
}
@media (max-width: 768px) {
  .title-heading .heading {
    font-size: 28px !important;
    font-weight: 900;
    color: #0037C6;
  }
}

.text-primary {
  color: #000000 !important;
  font-weight: 600;
}

.title-fredi-clu, .title-mini-creditos {
  letter-spacing: 0.5px;
  font-size: 30px !important;
  font-weight: bold;
  color: #0037C6;
}


.section-title .title {
  letter-spacing: 0.5px;
  font-size: 30px !important;
  font-weight: bold;
  text-transform: uppercase;
  color: #0037C6;
}
@media (max-width: 768px) {
  .section-title .title {
    font-size: 24px !important;
    font-weight: bold;
    text-transform: uppercase;
    color: #0037C6;
  }
}

.section-title .title-nuestros-servicios {
  letter-spacing: 0.5px;
  font-size: 2rem !important;
  font-weight: 700;
  color: #0037C6;
}
@media (max-width: 768px) {
  .section-title .title-nuestros-servicios {
    font-size: 24px !important;
    font-weight: bold;
    color: #0037C6;
  }
}


.img-about{
  /*width: 75px;*/
  height: 75px;
}

.img-acceder{
  /*width: 75px;*/
  height: 60px;
  margin-top: 45px;
  margin-left: 10px;
}

.title-about{
  font-size: 1.9em;
  font-weight: bold;
  margin: 0px;
}

.subtitle-about{
  font-size: 1rem;
  /*margin-top: -7px;*/
  margin: 0px;
}

.title-home{
  font-size: 14px;
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 0px;
}


.footer {
  /* background: #0037C6; */
  /* margin-top: 10rem !important; */
    /* color: #adb5bd;  
    position: absolute !important;
    bottom: 0 !important; */
  /* background-color: red !important; */
}


/* .fa-angle-right{
  background-color: red !important;
  width: 150px;
  position: absolute;
  top: 0;
} */

/* .carousel-control-prev-icon,
.carousel-control-next-icon {
  background-color: rgba(0, 0, 0, 0.5); 
  width: 50px;
  height: 50px;
  background-size: 100%, 100%;
} */


.carousel-control-prev, .carousel-control-next {
  z-index: 1; 
}


/* .next-icon{
  left: 110px;
}

.prev-icon{
  right: 110px;
} */

.prev-next-container{
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  background: transparent;
  /* margin-bottom: 106px; */
  position: absolute;
  top: 45%;
  z-index: 1;
}

@media (width <= 992px) {
  .prev-next-container{
    top: 50%;
  }
}

@media (width <= 600px) {
  .prev-next-container{
    top: 55%;
  }
}


/* .btn-prev, .btn-next{
  width: 100px;
} */

/* .prev-icon{
  width: 100%;
    display: flex;
    height: 100%;
} */



.metrics-list {
  display: grid;
  grid-template-columns: repeat(4, 200px);
  list-style-type: none;
  gap: 30px;
  margin: 0 auto;
  margin-bottom: 110px;
}

.metrics-list li:nth-child(1) {
  background-color: #F0C0B3;
}

.metrics-list li:nth-child(2) {
  background-color: #E6EDAA;
}

.metrics-list li:nth-child(3) {
  background-color: #E4C6DA;
}

.metrics-list li:nth-child(4) {
  background-color: #F9EEB2;
}

.metrics-list li {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 12px 48px !important;
  border-radius: 20px;
  text-align: center;
  height: 12.5rem;
}

.metrics-list li svg {
  margin-bottom: 25px !important;
}

.metrics-list li p:nth-of-type(1) {
  font-weight: 700;
}


@media (width <=1024px) {

  .metrics-list {
      grid-template-columns: 1fr;
      margin-bottom: 40px !important;
      width: 200px;
      padding-left: 0;
  } 

}

@media (width >1024px) {
  .metrics-list{
    justify-content: center;
    margin-top: 2.5rem;   
  }

}

@media (width <= 768px) {  
  
  .img-mobile-minicreditos, .img-mobile-noticias, .img-mobile-creditos{
    display: block;
  }
  .img-desktop-minicreditos, .img-desktop-noticias, .img-desktop-creditos {
    display: none;
  }
}


@media (width < 768px) { 

  .cabecera{
    text-align: center !important;
    font-size: 35px !important;
  }
  .texto{
    text-align: center !important;
    font-size: 18px !important;
  }
  .subtitulo{
    text-align: center !important;
    font-size: 30px !important;
  }
  .imagenMobile{
    position: relative !important;
    width: 150px !important;
    display: flex !important;
    background: radial-gradient(closest-side, #3DB9FF 60%, #1D2DBF) !important;
    margin-top: 3vh;
    margin-left: 12vh;
  }
  
  .imagenDesktop{
    display: none !important;
  }

}



@media (width > 768px) {
  .img-mobile-minicreditos, .img-mobile-noticias, .img-mobile-creditos{
    display: none;
  }
  .img-desktop-minicreditos, .img-desktop-noticias, .img-desktop-creditos{
    display: block;
  }

}

.btn-ingresar-al-simulador{
  border-radius: 1rem;
  background-color: #1D2DBF !important;
  color : white !important;
  outline: 1px solid white;
}

.btn-ingresar-al-simulador:hover{
  background-color: transparent !important;
  color : #1D2DBF !important;
  outline: 1px solid #1D2DBF;
}


.btn-solicitar-minicredito{
  background-color: white !important;
  color : #1D2DBF !important;
  border-radius: 1rem;
  margin-bottom: 0.5rem;
}

.btn-solicitar-minicredito:hover {
  background-color: #1D2DBF !important;
  color : white !important;
  outline: 1px solid white;
  transition: background-color 0.5s ease-in-out; 
  transition: color 0.3s ease-in-out; 
}
 /***************** ESTILOS PARA EL HOME  ****************************/
.cabecera{
  color: white !important;
  font-size: 60px;
  font-weight: 800 !important;
  margin-top: 100px;
  text-align: start;
}

.subtitulo{
  color: white !important;
  font-size: 45px;
  text-align: start;
}
.texto{
  color: white !important;
  font-size: 24px;
  text-align: start;
}
.imagenDesktop {
  margin-right: 10vw !important; /* Ajusta según el ancho de la pantalla */
  background: radial-gradient(closest-side, #3DB9FF 60%, #1D2DBF);
  top: 10vh; /* Ajusta según el alto de la pantalla */
  display: block;
}

.imagenMobile {
  display: none;
}
.btn-white {
  background-color: white;
  color : #1D2DBF;

}

.boton {
  border-radius: 40px;
  padding: 10px 15px;
  text-align: center;
  width: fit-content;
  font-size: 20px;
  border: none;
  display: block;
  font-weight: 200 !important;
}
.contenedor{
  top: 20vh !important;
}



@media (width >= 1500px) {
  .imagenDesktop{
    margin-top: 15vh !important;
  }
  .textocliente{
    margin-top: 25vh !important;
  }
  .cabecera{
    font-size: 66px;
  }
  .subtitulo{
    font-size: 50px;
  }
  .texto{
    font-size: 28px !important;
  }
  .boton{
    font-size: 20px !important;
  }
  
}
@media (min-width: 768px) and (max-width: 1024px) {
  .cabecera{
    font-weight: 55px !important;
  }
  .subtitulo{
    font-weight: 37px !important;
  }
  .text{
    font-weight: 26px !important;
  }

.imagenDesktop{
  width: 340px !important;
  margin-right: 3vw !important;
  top: 25vh !important;
  
}
.boton{
  text-align: start !important;
  display: table-cell !important;
}
}

.posicion-container{
  position: fixed !important;
  top: 3.25rem !important;
}

@media (width < 992px) {
  .posicion-container {
    /* top: 55% !important; */
    top: 87% !important;
    left: 50%;
    transform: translateX(-50%);
  }
}

@media (width < 768px) {
  .posicion-container {
    /* top: 40% !important; */
    top: 80% !important;
  }
}

.posicion-container-dashboard{
  top: 80% !important;
}

@media (width >= 992px) {
  .posicion-container-dashboard{
    top: 3.25rem !important;
  }
}

.toast-content-flex{
  display: flex;
  align-items: center;
  column-gap: 0.4rem;
}

.footer{
  position: relative !important;
}

.modal-dialog:has(.modal-notificaciones) {
  max-width: 630px !important;
}

@media(width <= 1400px) {
  .modal-dialog:has(.modal-notificaciones) {
    max-width: 600px !important;
  } 
}

@media(width <= 992px) {
  .modal-dialog:has(.modal-notificaciones) {
    /* max-width: 550px !important; */
  } 
}

@media(width <= 576px) {
  .modal-dialog:has(.modal-notificaciones) {
    max-width: 500px !important;
    margin: 1rem auto;
  } 

  .modal-notificaciones .emoji{
    width: 4rem !important;
  }

  .modal-notificaciones h4{
    font-size: 17px;
  }

  .modal-notificaciones .noti-subtitle, .modal-notificaciones a{
    font-size: 14px;
  }


  /* .modal-dialog:has(.emoji) {
    width: 4rem;
  } */
}

@media(width <= 420px) {
  .modal-dialog:has(.modal-notificaciones) {
    max-width: 500px !important;
    margin: 1rem auto;
  } 
}

.modal-content:has(.modal-aprobado) {
  border-radius: 1rem !important;
}

.modal-dialog:has(.modal-aprobado) {
  max-width: 800px !important;
}

@media(width <= 1400px) {
  .modal-dialog:has(.modal-notificaciones) {
    max-width: 600px !important;
  } 
}

@media(width <= 992px) {
  .modal-dialog:has(.modal-aprobado) {
    max-width: 650px !important;
  } 
}

@media(width <= 768px) {
  .modal-dialog:has(.modal-aprobado) {
    max-width: 550px !important;
  } 
}

@media(width <= 576px) {
  .modal-dialog:has(.modal-aprobado) {
    max-width: 440px !important;
    margin: 1rem auto;
  } 

  .modal-notificaciones .emoji{
    width: 4rem !important;
  }

  .modal-notificaciones h4{
    font-size: 17px;
  }

  .modal-notificaciones .noti-subtitle, .modal-notificaciones a{
    font-size: 14px;
  }


  /* .modal-dialog:has(.emoji) {
    width: 4rem;
  } */
}

@media(width <= 450px) {
  .modal-dialog:has(.modal-aprobado) {
    max-width: 400px !important;
    margin: 1rem auto;
  } 
}


@media(width <= 420px) {
  .modal-dialog:has(.modal-aprobado) {
    max-width: 385px !important;
    margin: 1rem auto;
  } 
}

@media(width <= 400px) {
  .modal-dialog:has(.modal-aprobado) {
    max-width: 370px !important;
  } 
}

@media(width <= 380px) {
  .modal-dialog:has(.modal-aprobado) {
     max-width: 350px !important;
  } 
}

@media(width <= 360px) {
  .modal-dialog:has(.modal-aprobado) {
     max-width: 335px !important;
  } 
}


.spinner-container-comunicaciones {
  display: flex;
  justify-content: center;
  width: 100%;
  position: fixed;
  top: 15rem;
}


.spinner-container-noticias {
  display: flex;
  justify-content: center;
  width: 100%;
  position: fixed;
  top: 15rem;
  z-index: 1;
}

.spinner-container-noticias-detalles {
  display: flex;
  justify-content: center;
  width: 100%;
  position: fixed;
  top: 25rem;
  z-index: 1;
}

.spinner-btn {
  color: #2f55d4;
  width: 4rem;
  height: 4rem;
}


@media (width >= 1800px) {
  .footer-absolute{
    position: absolute !important;
    bottom: 0 !important;
  }
}
